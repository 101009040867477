import React from "react";
import Timer from "./components/Timer";
import SocialIcons from "./components/SocialIcons.js";

const App = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <main className="container mx-auto px-4 py-16 flex flex-col items-center justify-center min-h-screen">
        <h1 className="text-4xl md:text-6xl font-bold text-center mb-6">
          Welcome to{" "}
          <span className="text-blue-600 hover:underline cursor-pointer">
            Businessstanley
          </span>
        </h1>
        <p className="text-xl md:text-2xl text-gray-600 mb-12">
          Coming soon in
        </p>
        <Timer />
        <SocialIcons />
      </main>
    </div>
  );
};

export default App;

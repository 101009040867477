import React, { useState, useEffect } from "react";
import { Circle } from "lucide-react";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const Timer = () => {
  const [remainingTime, setRemainingTime] = useState(() => {
    const startTime = Date.now() / 1000;
    const endTime = startTime + daySeconds * 30;
    return endTime - startTime;
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prev) => {
        if (prev <= 0) return 0;
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getTimeValues = () => {
    const days = Math.floor(remainingTime / daySeconds);
    const hours = Math.floor((remainingTime % daySeconds) / hourSeconds);
    const minutes = Math.floor((remainingTime % hourSeconds) / minuteSeconds);
    const seconds = Math.floor(remainingTime % minuteSeconds);

    return { days, hours, minutes, seconds };
  };

  const TimeUnit = ({ value, label, color }) => (
    <div className="relative flex flex-col items-center">
      <svg className="w-32 h-32">
        <circle
          cx="64"
          cy="64"
          r="58"
          className="stroke-gray-200 fill-none"
          strokeWidth="6"
        />
        <circle
          cx="64"
          cy="64"
          r="58"
          className={`fill-none ${color}`}
          strokeWidth="6"
          strokeDasharray={2 * Math.PI * 58}
          strokeDashoffset={
            2 *
            Math.PI *
            58 *
            (1 -
              value /
                (label === "days"
                  ? 30
                  : label === "hours"
                  ? 24
                  : label === "minutes"
                  ? 60
                  : 60))
          }
          transform="rotate(-90 64 64)"
        />
      </svg>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <div className="text-3xl font-bold">{value}</div>
        <div className="text-sm mt-1 text-gray-600">{label}</div>
      </div>
    </div>
  );

  const { days, hours, minutes, seconds } = getTimeValues();

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-8 p-4">
      <TimeUnit value={days} label="days" color="stroke-purple-600" />
      <TimeUnit value={hours} label="hours" color="stroke-pink-600" />
      <TimeUnit value={minutes} label="minutes" color="stroke-rose-400" />
      <TimeUnit value={seconds} label="seconds" color="stroke-teal-600" />
    </div>
  );
};

export default Timer;
